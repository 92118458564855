import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { LoginService } from '../core/login.service';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'is-what';

import { SelectItem } from 'primeng/api';

import { LookupDataService } from '../services/lookup-data.service';
import { PartsContentService } from '../services/partsContent.service';

import { Model } from '../model/model';
import { PartsContent } from '../model/partsContent';
import { EmailProperties } from '../model/emailProperties';

import { EmailService } from '../services/email.service';
import { APIService } from '../services/api.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partsContent',
    templateUrl: './partsContent.component.html',
    styleUrls: ['./partsContent.component.css']
})
export class PartsContentComponent implements OnInit {

    msg: string = "";
    reportProgress: boolean = false;
    updateProgress: boolean = false;
    approveProgress: boolean = false;
    labelProgress: boolean = false;

    yearOptions: SelectItem[];
    modelOptions: SelectItem[];

    partsContent: PartsContent;
    emailProperties: EmailProperties;
    model = new Model('', '', '', '', '');

    showFields: boolean = false;

    selectedYear: string;
    selectedModelType: string;
    selectedTransmission: string;

    userID: string;

    constructor(private sanitizer: DomSanitizer,
        public loginService: LoginService,
        private lookupService: LookupDataService,
        private partsContentService: PartsContentService,
        private emailService: EmailService,
        private apiService: APIService,
        private router: Router,
        private titleService: Title) {
        this.titleService.setTitle("Parts Content");
        this.getModelYears();
        this.getModelType();
        this.getTransmissions();
    }

    years: any[];
    modelType: any[];
    transmission: any[];
    ftype: any[];
    options: any[];



    ngOnInit() {
        const userSession = this.loginService.getUserSession();
        this.userID = this.loginService.getUserSession()['userName'];
        if (isNullOrUndefined(userSession)) {
            this.router.navigate(['login']);
        }
    }

    getModelYears() {
        if (!this.years) {
            this.getLookupData('modelYear').subscribe(resp => {
                this.years = resp;                
                // for (var i = 0; i <= this.years.length; i++) {
                //     this.yearOptions = [{ label: this.years[i].field, value: this.years[i].value}];
                // }
            });
        }
    }

    getModelType() {
        if (!this.modelType) {
            this.getLookupData('modelType').subscribe(resp => {
                this.modelType = resp;
                // for (let i = 0; i < this.modelType.length; i++) {
                //     this.modelOptions = [{ label: this.modelType[i].field, value: this.modelType[i].value }];
                // }
            });
        }
    }

    getTransmissions() {
        if (!this.transmission) {
            this.getLookupData('transmission').subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    getLookupData(type: string) {
        return this.lookupService.getLookupData(type);
    }

    clearEntries() {
        this.model.modelType = "";
        this.model.modelYear = "";
        this.model.transmission = "";
        this.selectedYear = "";
        this.selectedModelType = "";
        this.selectedTransmission = "";
        this.showFields = false;
        this.to = "";
        this.subject = "";
        this.message = "";
        this.cc = "";
        this.bcc = "";
        this.releaseEmails = "";
        this.posDate = "";
        this.msg = "";
    }

    changeYear(event, type) {        

        this.selectedYear = this.model.modelYear;
        if (this.selectedYear != null) {
            this.lookupService.getModelTypeLookupDataByYear(this.selectedYear).subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    changeModelType(event, type) {        

        if (this.modelType != null) {
            for (var i in this.modelType) {
                if (this.model.modelType === this.modelType[i].value) {
                    this.selectedModelType = this.modelType[i].field;
                }
            }
        }

        if (this.selectedYear != null && this.selectedModelType != null) {
            this.lookupService.getTransmissionLookupDataByYearAndType(this.selectedYear, this.selectedModelType).subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    changeTransmission(event, type) {        

        if (this.transmission != null) {
            for (var i in this.transmission) {
                if (this.model.transmission === this.transmission[i].value) {
                    this.selectedTransmission = this.transmission[i].field;
                }
            }
        }

        this.getPosDate(this.selectedYear, this.selectedModelType, this.selectedTransmission);
    }

    posDate: string;
    getPosDate(selectedYear, selectedModelType, selectedTransmission) {
        this.lookupService.getPOSDate(selectedYear, selectedModelType, selectedTransmission).then((posDate) => {
            this.posDate = posDate;
        });
    }

    async view() {        

        this.msg = "";
        this.reportProgress = true;
        if (this.selectedYear != null && this.selectedModelType != null && this.selectedTransmission != null) {
            this.partsContent = await this.partsContentService.getPartsContentData(this.selectedYear, this.selectedModelType, this.selectedTransmission);
            if (this.partsContent.errors != null) {
                this.msg = "No Data available.";
            }
            this.reportProgress = false;
            this.showFields = true;
        }
        if (this.selectedYear <= '2023') {
            //this will disable the approve and update buttons            
            this.partsContent.updateFlag = 'Y';
            this.partsContent.approveFlag = 'Y';
        }
        setTimeout(() => { this.reportProgress = false }, 500);
    }

    getEmailProperties(emailType: string) {        
        this.to = "";
        this.from = "";
        this.subject = "";
        this.message = "";
        this.cc = "";
        this.bcc = "";
        this.releaseEmails = "";
        this.emailService.getEmailProperties(emailType).subscribe((properties) => {
            this.emailProperties = properties;

            if (this.emailProperties.errors != null) {
                this.msg = "Error Fetching email properties";
            }

            this.from = this.emailProperties.emailFrom;
            this.subject = this.emailProperties.emailSubject;
            this.message = this.emailProperties.emailBody;
            this.releaseEmails = this.emailProperties.releaseEmails;
            this.cc = this.emailProperties.emailCCTo;
            this.bcc = this.emailProperties.emailBCCTo;

            if (this.releaseEmails === "Y") {
                this.to = this.emailProperties.emailTo;
            } else {                
                this.to = this.bcc;
            }            
        });

    }

    to: string;
    from: string;
    subject: string;
    message: string;
    cc: string;
    bcc: string;
    releaseEmails: string;

    async updateData() {
        this.updateProgress = true;
        this.msg = "";

        this.getEmailProperties("PartsContentUpdate");

        this.partsContentService.updatePartsContentData({ partsContent: this.partsContent.partsContent, foreignSource1: this.partsContent.foreignSource1, foreignContent1: this.partsContent.foreignContent1, foreignSource2: this.partsContent.foreignSource2, foreignContent2: this.partsContent.foreignContent2, assembly: this.partsContent.assembly, engineOrigin: this.partsContent.engineOrigin, transmissionOrigin: this.partsContent.transmissionOrigin, modelYear: this.selectedYear, modelType: this.selectedModelType, transmissionID: this.selectedTransmission, userID: this.userID }).then((result) => {
            if (result) {
                this.msg = "Update complete.";
                let button = <HTMLButtonElement>document.getElementById('updateButton');
                button.disabled = true;
                // this.view();

                let message = "Model Year: " + this.selectedYear + ", Model Type: " + this.selectedModelType + ", Transmission: " + this.model.transmission;
                this.message = message + " " + this.message;
                this.emailService.sendEmail(this.from, this.to, this.subject, this.message, this.cc, this.bcc).then((res) => {
                    
                });
            }
            else
                this.msg = "Update Failed.";
        });
        setTimeout(() => { this.updateProgress = false }, 500);
    }

    async approveData() {
        this.approveProgress = true;
        this.msg = "";
        this.getEmailProperties('PartsContentApproval');
        // const emailproperties = (await this.getEmailProperties('PartsContentApproval'));        

        this.partsContentService.approvePartsContentData(this.selectedYear, this.selectedModelType, this.selectedTransmission).then((result) => {
            if (result) {
                this.msg = "Approval complete.";
                let button = <HTMLButtonElement>document.getElementById('approveButton');
                button.disabled = true;

                let message = "Model Year: " + this.selectedYear + ", Model Type: " + this.selectedModelType + ", Transmission: " + this.model.transmission;
                this.message = message + " " + this.message;
                this.emailService.sendEmail(this.from, this.to, this.subject, this.message, this.cc, this.bcc).then((res) => {
                    
                });
            }
            else
                this.msg = "Approval Failed.";
        });
        setTimeout(() => { this.approveProgress = false }, 500);
    }

    async viewLabel() {
        this.labelProgress = true;
        this.msg = "";           

        await this.apiService.getLabelProofForParts(environment.partsContentTemplateID, this.selectedYear, this.selectedModelType, this.selectedTransmission).subscribe((res) => {                        
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(res);
            var dd = new Date;
            var date = new Date().getFullYear() + "-" + (dd.getMonth() + 1) + "-" + dd.getDate();
            var seconds = dd.getTime() / 1000;
            var fileName = "Parts_Content_" + this.selectedYear + "_" + this.selectedModelType + "_" + date + "_" + seconds.toString() + ".pdf";            
            // link.setAttribute('download', fileName);
            link.setAttribute('target', '_blank');
            link.click();
            this.labelProgress = false;
        }), error => {
            console.error("Error occured with the API response: " + JSON.stringify(error));
        }
    }

}
