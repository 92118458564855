import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { LoginService } from '../core/login.service';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'is-what';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    public cmsPageUrl: SafeResourceUrl = "https://cms.prod.imperialm.net/imi-cms/page/show/574?preview=";
    public cmsPageMode: string = "draft";

    constructor(private sanitizer: DomSanitizer,
        public loginService: LoginService,
        private router: Router,
        private titleService: Title) {
        this.titleService.setTitle("Porsche - Home");
    }

    ngOnInit() {
        const userSession = this.loginService.getUserSession();
        if (!isNullOrUndefined(userSession)) {
            if (location.host === 'www.porschedashboard.helm.com' || location.host === 'porschedashboard.helm.com') {
                this.cmsPageMode = 'published';
            }
            this.cmsPageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.cmsPageUrl + this.cmsPageMode);
        } else {
            this.router.navigate(['login']);
        }
    }
}
