<nav *ngIf="showNav" class="navbar navbar-inverse navbar-global navbar-fixed-top" aria-label="header"
    style="position: fixed;z-index: 1000;width: 100%; height: 100px; transition-duration: 0.25s, 0.25s;transition-property: margin-left, margin-right;flex: 0 0 60px;">
    <div class="container-fluid">
        <div class="navbar-header">
            <a *ngIf="showNav" style="cursor: pointer" class="btn-expand-collapse" (click)="toggle()"><span
                    style="font-size: 1em; color:rgba(0, 0, 0, .25)"><em class="fas fa-bars"></em></span></a>
            &nbsp;
            <a class="navbar-brand" routerLink='/home'><img src="./header_logo.png" style="height:88px;"
                    alt="Header Logo" /></a>
        </div>
        <div *ngIf="!showNav" id="navbar">
            <div class="mr-auto">
            </div>
            <span class="navbar-text">
                <a routerLink="/login" href="#">Login</a>
            </span>
        </div>

        <div *ngIf="showNav" id="navbar">
            <div class="mr-auto">
            </div>
            <div>
                <span class="navbar-text">
                    Welcome, {{userFullName}}
                </span>
                <span class="navbar-text">
                    <a class="nav-link" routerLink="/contactPage">Contact Us</a>
                </span>
                <span class="navbar-text">
                    <a class="nav-link" (click)="logout()" href="#">Logout</a>
                    <!-- <a *ngIf="emulating" class="nav-link" (click)="stopEmulating()" href="#">Stop Emulating</a> -->
                </span>
            </div>
        </div>
    </div>
</nav>
<div *ngIf="showNav" class="app-body">
    <div class="sidebar">
        <nav class="navbar-primary" aria-label="Site menu">
            <ul class="navbar-primary-menu">
                <li class="nav-item">
                    <a routerLink="/home"><span class="nav-label">Home</span></a>
                </li>
                <li class="nav-item">
                    <a routerLink="/dashboard"><span class="nav-label">Dashboard</span></a>
                </li>
                <li class="nav-item" *ngIf="fuelTab">
                    <a routerLink="/fuelEconomy"><span class="nav-label">Fuel Economy & Environment</span></a>
                </li>
                <li class="nav-item" *ngIf="partsTab">
                    <a routerLink="/partsContent"><span class="nav-label">Parts Content Information</span></a>
                </li>
                <li class="nav-item" *ngIf="productTab">
                    <a routerLink="/productContent"><span class="nav-label">Product Content</span></a>
                </li>
                <li class="nav-item" *ngIf="fuelTab || partsTab || productTab">
                    <a routerLink="/previewLabel"><span class="nav-label">Preview  Full Label</span></a>
                </li>
                <li class="nav-item" *ngIf="isAdmin">
                    <a routerLink="/adminPage"><span class="nav-label">Admin</span></a>
                </li>
            </ul>
        </nav>
    </div>
</div>